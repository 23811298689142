import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { PaypalPayment } from '../components/sections';
import ReplacementPartsPaypal from '../components/sections/PaypalPayment/ReplacementPartsPaypal';
import { stackStyled } from '../theme';
import { useViewPortSize } from '../hooks';
import { StackOnBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Containers';

const BreadCrumbsContainer = stackStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '10px' : '75px',
    width: '50%',
    textAlign: 'left',
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '100px 10px' : '50px 10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

export const KeysReplacementPayment: React.FC<PageProps> = (
  props: PageProps
) => {
  const [isMobile, isTablet] = useViewPortSize();
  const [showPaypal, setShowPaypal] = useState(false);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll id='main'>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Customer service', href: '/customer-service' },
              {
                title: 'Replacement Parts Request',
                href: '',
              },
              { title: 'Payment', href: '/replacement-parts-payment' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          {showPaypal ? (
            <ReplacementPartsPaypal
              title={'Replacement parts request'}
              subTitle={
                'You requested a replacement part for your Cannon Safe. Our Customer Service team has set up a secure PayPal page in order to purchase your part.'
              }
            />
          ) : (
            <PaypalPayment secondStep={() => setShowPaypal(true)} />
          )}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacementPayment;
