import React, { useState } from 'react';
import PaypalSuccess from '-!svg-react-loader!../../../assets/payment-completed-card.svg';
import PayPalIcon from '-!svg-react-loader!../../../assets/paypal-icon.svg';
import PayPal from '../../../atoms/PayPal';
import { Button, CustomInput } from '../../../atoms';
import { stackStyled, Typography, useStackStyletron } from '../../../theme';
import { useOrientation, useViewPortSize } from '../../../hooks';

const ButtonContainer = stackStyled('div', {
  paddingTop: '30px',
  width: '100%',
});

const MainContainer = stackStyled(
  'div',
  ({ isRotated }: { isRotated: boolean }) => ({
    padding: isRotated ? '50px 0px 50px 0px' : '',
    width: '80%',
  })
);

interface ICombinationPaypal {
  title: string;
  subTitle: string;
}

const ReplacementPartsPaypal = (props: ICombinationPaypal) => {
  // HOOKS

  //STATE
  const [css, theme] = useStackStyletron()
  const [isMobile, isTablet] = useViewPortSize();
  const [amount, setAmount] = useState(0);
  const [showPaypal, setShowPaypal] = useState(false);
  const currency = 'USD';
  
  // RENDER
  const { isRotated } = useOrientation();
  return (
    <MainContainer isRotated={isRotated}>
      <ButtonContainer>
        {showPaypal ? (
          <PayPal
            amount={amount}
            currency={'USD'}
            items={[]}
            successfulImage={PaypalSuccess}
            description={'Replacement parts'}
            successfulText={
              'We have sent your replacement parts confirmation to your mail. Be sure emails from cannonsecurityproducts.com and cannonsafe.com are on your safe sender list.'
            }
            successfulTitle={'Your payment was completed successfully'}
            onPaymentApproved={(value) => {
              // handleSendData(value);
              // setPaymentDone(true);
            }}
          />
        ) : (
          <>
            <PayPalIcon />
            <Typography
              $fontFamily={"Montserrat"}
              $weight={700}
              $tagType={isMobile ? 'h4Mob' : "h4"}
              $textAlign={isMobile ? "left" : "center"}
              $color={theme.stackOnColors.primaryGreenDark}
              $margin={20}
            >
              {props.title}
            </Typography>
            <Typography
              $fontFamily={"Montserrat"}
              $weight={400}
              $tagType={"body"}
              $textAlign={isMobile ? "left" : "center"}
              $color={theme.stackOnColors.primaryBlack}
              $margin={5}
            >
              {props.subTitle}
            </Typography>
            <CustomInput
              type='number'
              noMarginLeft
              errorMessage='Field Required'
              error={showPaypal}
              positive={amount !== 0}
              label='Total amount'
              placeholder='100'
              value={amount}
              onChange={(e: any) => setAmount(e.currentTarget.value)}
              required
              requiredStyle
            />
            <Button.RoundedButton
              onClick={() => {
                amount <= 0 ? setShowPaypal(false) : setShowPaypal(true);
              }}
              size='compact'
              color='primary'
              $style={{
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '12px',
                paddingRight: '12px',
                width: '40%',
                marginTop: '30px',
              }}
            >
              Checkout
            </Button.RoundedButton>
          </>
        )}
      </ButtonContainer>
    </MainContainer>
  );
};

export default ReplacementPartsPaypal;
